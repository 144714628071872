.shell {
  width: 100%;
  overflow: hidden;
}
.shell .l {
  width: 30%;
  float: left;
}
.shell .r {
  width: 70%;
  float: left;
}
.shell .p2 {
  margin-top: 20px;
}
